import "./intro.scss"
import { init } from 'ityped'
import { useEffect, useRef } from "react"

export default function Intro() {

  const textRef = useRef();
  useEffect(() => {
    init(textRef.current, {
     showCursor: true, 
     backDelay:  1500,
     strings: ["Developer", "Designer"],
     });
  }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
      <div className="imageContainer">
        <img src="assets/man.jpg" alt="man" />
      </div>
      </div>

      <div className="right">
        <div className="wrapper">
          <h2>Hi There, I'm</h2>
          <h1>Ebad Gorekar</h1>
          <h3>Freelance  <span ref={textRef}></span></h3>
        
        <a href="#portfolio">
          <img src="assets/arrow-down.png" alt="arrowdown" />
        </a>
        </div>
      </div>
    </div>
  )
}
